import React from 'react';

export default function Logo() {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 85 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="42.5" cy="42.5" r="42.5" fill="#F26E22" />
      <path
        d="M45.7505 49.6604C42.9351 49.6604 40.6499 51.9728 40.6499 54.8289C40.6499 57.6838 42.9363 60 45.7505 60C48.5672 60 50.8499 57.6838 50.8499 54.8289C50.8511 51.9715 48.5672 49.6604 45.7505 49.6604ZM45.7505 57.5651C44.2559 57.5651 43.0489 56.3414 43.0489 54.8289C43.0489 53.3177 44.2559 52.0878 45.7505 52.0878C47.2402 52.0878 48.4534 53.3177 48.4534 54.8289C48.4547 56.3414 47.2414 57.5651 45.7505 57.5651ZM66.8546 26.8674C65.0497 26.245 53.4027 25 45.7355 25C38.0696 25 25.7233 26.2612 23.896 26.8012C23.7071 26.8562 18.2538 28.3586 18.2538 34.2672V50.0366C18.2538 51.1078 18.6553 52.499 19.2482 52.499H12V54.9989H39.4729C39.4729 51.2491 42.3022 48.5967 45.7868 48.5967C49.2764 48.5967 52.1044 51.2491 52.1044 54.9989H67.4024C69.9652 54.9989 72.0365 52.629 72.0365 50.0366V33.4284C72.0365 30.8348 70.0853 27.9836 66.8546 26.8674ZM35.8095 39.2157C35.8095 39.7082 35.458 39.9995 34.9752 39.9995H26.7415C26.2549 39.9995 25.7584 39.8145 25.7584 39.322V33.4947C25.7584 33.0047 26.2549 32.4997 26.7402 32.4997H34.974C35.4568 32.4997 35.7644 33.0047 35.7644 33.4947V39.322L35.8095 39.2157ZM65.7827 39.322C65.7827 39.8145 65.395 39.9995 64.9072 39.9995H46.6886C46.202 39.9995 45.7705 39.8145 45.7705 39.322V33.4947C45.7705 33.0047 46.202 32.4997 46.6886 32.4997H64.9072C65.3962 32.4997 65.7827 33.0047 65.7827 33.4947V39.322Z"
        fill="white"
      />
    </svg>
  );
}
